import { useQueryClient } from '@tanstack/react-query'

import { QUERY_KEYS } from '../lib/queryKeys'
import { useCompanyProfileSearchParams } from './useCompanyProfileSearchParams'

// Use this hook to invalidate all panel-related queries when making any changes
// to panel data
export function useInvalidatePanelQueries() {
  const companyProfileSearchParams =
    useCompanyProfileSearchParams().searchParams
  const queryClient = useQueryClient()

  return async () => {
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.panelPartners(),
    })
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.panelAgreements(),
    })
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.panels() })
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.panelStats() })
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.referralServices(),
    })
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.availableRecommendedPartners(),
    })
    await queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.recommendedPartners(),
    })

    // Invalidate company profile query so the new panel status is shown
    if (
      companyProfileSearchParams.identifier &&
      companyProfileSearchParams.identifierType
    ) {
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.companyProfile({
          identifier: companyProfileSearchParams.identifier,
          identifierType: companyProfileSearchParams.identifierType,
        }),
      })
    }
  }
}
