import { PaginationFilters } from '../../types/api'
import { ReviewCollection, ReviewItem } from '../../types/responses/review'
import apiService from './apiService'

class ReviewService {
  async getReviewsForCompany(
    companyId: ID,
    filters: PaginationFilters,
  ): Promise<ReviewCollection> {
    const params = { 'company.id': companyId, ...filters }
    const response = await apiService.get<ReviewCollection>('/v1/reviews', {
      params,
    })

    return response.data
  }

  async getReview(reviewId: ID): Promise<ReviewItem> {
    const response = await apiService.get<ReviewItem>(`/v1/reviews/${reviewId}`)

    return response.data
  }
}

const reviewService = new ReviewService()

export default reviewService
