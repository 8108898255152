import queryString from 'query-string'

import { CompanyProfileSearchParams } from '../../components/misc/CompanyProfile/CompanyProfile'
import {
  ReferralConsentType,
  ReferralMethod,
} from '../../redux/slices/referClient'
import {
  CommercialAgreementReferralDirection,
  CompanyTypeCode,
  CompassPdfReportSection,
  ReferralTypeOption,
} from '../../types/misc'
import { ROUTES } from '../routes'

// TODO: Move all the URL helpers to this service
class RouteService {
  addRelationship(searchParams?: AddRelationshipSearchParams) {
    return `${ROUTES.addRelationship}?${queryString.stringify(
      searchParams ?? {},
    )}`
  }

  // TODO: Move panelId out of searchParams and as a separate parameter
  addRelationshipAgreement(
    panelId: number,
    searchParams: AddRelationshipAgreementSearchParams,
  ) {
    const queryStringParam = queryString.stringify(searchParams)

    return `${ROUTES.addRelationshipAgreement.replace(
      ':panelId',
      panelId.toString(),
    )}?${queryStringParam}`
  }

  companyProfile(searchParams: CompanyProfileSearchParams) {
    return `${ROUTES.companyProfile}?${queryString.stringify(searchParams)}`
  }

  compassPdfReportPreview(params: CompassPdfReportPreviewPageParams) {
    return `${ROUTES.compassPdfReportPreview}?${queryString.stringify(params)}`
  }

  compassPdfReportSection(
    section: CompassPdfReportSection,
    params: CompassPdfReportPreviewSearchParams,
  ) {
    return `${ROUTES.compassPdfReportSection.replace(':section', section)}?${queryString.stringify(params)}`
  }

  referClient(params: ReferClientSearchParams) {
    const searchParamsString = queryString.stringify(params)
    return `${ROUTES.referClient}?${searchParamsString}`
  }

  referralPartners(searchParams?: ReferralPartnersSearchParams) {
    const params = searchParams || {}

    if (!params.hasSentFirstInvite) {
      delete params.hasSentFirstInvite
    }

    return `${ROUTES.professionalNetworkRelationships}?${queryString.stringify(params)}`
  }
}

const routeService = new RouteService()

export interface AddRelationshipSearchParams {
  hasSentFirstInvite?: boolean
}

export interface AddRelationshipAgreementSearchParams {
  referralDirection?: CommercialAgreementReferralDirection
  returnPath?: string
}

export interface CompassPdfReportPreviewPageParams {
  token: string
  accessToken?: string
}

export interface CompassPdfReportSearchParams {
  token: string
  accessToken?: string
  browserlessKey?: string
}

export type CompassPdfReportPreviewSearchParams = CompassPdfReportSearchParams

export const COMPASS_PDF_REPORT_PREVIEW_SEARCH_PARAMS: Record<
  keyof CompassPdfReportSearchParams,
  keyof CompassPdfReportSearchParams
> = {
  token: 'token',
  accessToken: 'accessToken',
  browserlessKey: 'browserlessKey',
}

export const ADD_RELATIONSHIP_AGREEMENT_SEARCH_PARAMS: Record<
  keyof AddRelationshipAgreementSearchParams,
  keyof AddRelationshipAgreementSearchParams
> = {
  referralDirection: 'referralDirection',
  returnPath: 'returnPath',
}

export interface ReferClientSearchParams {
  step?: string
  referralMethod?: ReferralMethod
  serviceAreaIds?: number[]
  companyTypeCode?: CompanyTypeCode
  referralFirmId?: number
  referralFirmIndividuals?: number[]
  clientId?: number
  shouldAttachLatestCompassReport?: boolean
  consentType?: ReferralConsentType
  referralTypeOption?: ReferralTypeOption
}

export const REFER_CLIENT_SEARCH_PARAMS: Record<
  keyof ReferClientSearchParams,
  keyof ReferClientSearchParams
> = {
  step: 'step',
  referralMethod: 'referralMethod',
  clientId: 'clientId',
  referralFirmId: 'referralFirmId',
  referralFirmIndividuals: 'referralFirmIndividuals',
  companyTypeCode: 'companyTypeCode',
  serviceAreaIds: 'serviceAreaIds',
  shouldAttachLatestCompassReport: 'shouldAttachLatestCompassReport',
  consentType: 'consentType',
  referralTypeOption: 'referralTypeOption',
}

export type ReferralPartnersSearchParams = {
  hasSentFirstInvite?: boolean
  companyName?: string
  identifier?: string
  identifierType?: string
  tab?: string
}

export const REFERRAL_PARTNERS_SEARCH_PARAMS: Record<
  keyof ReferralPartnersSearchParams,
  keyof ReferralPartnersSearchParams
> = {
  hasSentFirstInvite: 'hasSentFirstInvite',
  companyName: 'companyName',
  identifier: 'identifier',
  identifierType: 'identifierType',
  tab: 'tab',
}

export default routeService
