import React from 'react'

import { useRecommendedPartnerSteps } from '../../../hooks/useRecommendedPartnerSteps'
import RecommendedPartnerTabLink from './RecommendedPartnerTabLink'

const RecommendedPartnerTabLinks: React.FC = () => {
  const { tabbedStepElements } = useRecommendedPartnerSteps()

  return (
    <section className="d-flex flex-column flex-md-row flex-md-wrap">
      {tabbedStepElements.map((stepElement, index) => (
        <RecommendedPartnerTabLink
          key={index}
          stepElement={stepElement}
          index={index}
        />
      ))}
    </section>
  )
}

export default RecommendedPartnerTabLinks
