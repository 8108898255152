import classNames from 'classnames'
import React from 'react'
import Skeleton, { SkeletonProps } from 'react-loading-skeleton'

type Props = SkeletonProps & {
  length: number
}

const SkeletonText: React.FC<Props> = ({
  length,
  style,
  className,
  ...props
}) => {
  return (
    <Skeleton
      style={{ ...style, width: `${length}ch` }}
      className={classNames(className)}
      {...props}
    />
  )
}

export default SkeletonText
