import React from 'react'
import { Badge } from 'react-bootstrap'

import { useRecommendedPartner } from '../../../../hooks/useRecommendedPartner'
import HtmlContent from '../../components/HtmlContent'
import SectionHeading from '../../components/SectionHeading'

const WhereWeCanHelp: React.FC = () => {
  const { situationsWeHelp, clientSegmentsList, aboutUs } =
    useRecommendedPartner()

  const hasClientSegments = clientSegmentsList.length > 0

  return (
    <>
      <section className="d-flex flex-column flex-md-row gap-5">
        {(situationsWeHelp || hasClientSegments) && (
          <div className="d-flex flex-column gap-4 w-100 w-md-50">
            {situationsWeHelp && (
              <div>
                <SectionHeading>Situations we help</SectionHeading>
                <div dangerouslySetInnerHTML={{ __html: situationsWeHelp }} />
              </div>
            )}

            {hasClientSegments && (
              <div>
                <SectionHeading>Client segments and profiles</SectionHeading>
                {clientSegmentsList.map((segment) => (
                  <Badge
                    key={segment}
                    bg="light"
                    className="mt-2 me-2 badge-pill p-2 text-base text-black"
                  >
                    {segment}
                  </Badge>
                ))}
              </div>
            )}
          </div>
        )}

        <div className="w-100 w-md-50">
          <SectionHeading>About us</SectionHeading>
          <HtmlContent html={aboutUs} />
        </div>
      </section>
    </>
  )
}

export default WhereWeCanHelp
