import { RecommendedPartnerItem } from '../../types/responses/recommended-partners'
import apiService from './apiService'

class RecommendedPartnerService {
  private endpoint = '/v1/recommended-partners'

  async getRecommendedPartner(
    recommendedPartnerId: number,
  ): Promise<RecommendedPartnerItem> {
    const response = await apiService.get(
      `${this.endpoint}/${recommendedPartnerId}`,
    )

    return response.data
  }

  isValidStep(
    recommendedPartner: RecommendedPartnerItem,
    step: string | null,
  ): step is RecommendedPartnerStep {
    // `videoIntro` is only valid if the recommended partner has a video URL
    if (step === RECOMMENDED_PARTNER_STEP.watchVideo) {
      return !!recommendedPartner.videoUrl
    }

    return (
      !!step &&
      Object.values(RECOMMENDED_PARTNER_STEP).includes(
        step as RecommendedPartnerStep,
      )
    )
  }

  async acceptRecommendedPartner(
    recommendedPartnerId: number,
  ): Promise<unknown> {
    const response = await apiService.post(
      `${this.endpoint}/${recommendedPartnerId}/accept`,
      {},
    )

    return response.data
  }
}

export type RecommendedPartnerStep =
  | 'watchVideo'
  | 'whereWeCanHelp'
  | 'whatOurClientsSay'
  | 'whatProfessionalsSay'
  | 'workingWithYou'

export const RECOMMENDED_PARTNER_STEP: Record<
  RecommendedPartnerStep,
  RecommendedPartnerStep
> = {
  watchVideo: 'watchVideo',
  whereWeCanHelp: 'whereWeCanHelp',
  whatOurClientsSay: 'whatOurClientsSay',
  whatProfessionalsSay: 'whatProfessionalsSay',
  workingWithYou: 'workingWithYou',
}

const recommendedPartnerService = new RecommendedPartnerService()

export default recommendedPartnerService
