import React from 'react'

import { useRecommendedPartner } from '../../../../hooks/useRecommendedPartner'
import HtmlContent from '../../components/HtmlContent'
import SectionContainer from '../../components/SectionContainer'

const WorkingWithYou: React.FC = () => {
  const workingWithYou = useRecommendedPartner().workingWithYou

  return (
    <SectionContainer>
      <HtmlContent html={workingWithYou || ''} />
    </SectionContainer>
  )
}

export default WorkingWithYou
