import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

import reviewService from '../../../../../../lib/services/reviewService'
import SkeletonRow from '../../../../../skeleton/SkeletonRow'
import ReviewStats from './ReviewStats'

interface Props {
  reviewId: ID
}

const ReviewDetails: React.FC<Props> = ({ reviewId }) => {
  const reviewQuery = useQuery({
    queryKey: ['review-details', { reviewId }],
    queryFn: () => reviewService.getReview(reviewId),
  })

  const review = reviewQuery.data

  if (reviewQuery.isLoading) {
    return <SkeletonRow />
  }

  if (!review || reviewQuery.error) {
    throw new Error(`Failed to fetch review with ID: ${reviewId}`)
  }

  return (
    <Row>
      <Col lg={8} className="mb-4 mb-lg-0">
        <ReactMarkdown className="ReviewDetails__body">
          {review.body}
        </ReactMarkdown>
      </Col>

      <Col lg={4}>
        <ReviewStats review={review} />
      </Col>
    </Row>
  )
}

export default ReviewDetails
