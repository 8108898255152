import { useSuspenseQuery } from '@tanstack/react-query'
import invariant from 'tiny-invariant'

import { useRecommendedPartnerModal } from '../../../hooks/useRecommendedPartnerModal'
import { QUERY_KEYS } from '../../../lib/queryKeys'
import recommendedPartnerService from '../../../lib/services/recommendedPartnerService'

export function useRecommendedPartnerQuery() {
  const recommendedPartnerId = Number(
    useRecommendedPartnerModal().params.recommendedPartnerId,
  )

  invariant(
    recommendedPartnerId,
    'Cannot useRecommendedPartnerQuery without the recommendedPartnerId search param',
  )

  return useSuspenseQuery({
    queryKey: QUERY_KEYS.recommendedPartner(recommendedPartnerId),
    queryFn: () =>
      recommendedPartnerService.getRecommendedPartner(recommendedPartnerId),
    refetchOnWindowFocus: false,
    staleTime: 20000,
  })
}

export function useRecommendedPartner() {
  return useRecommendedPartnerQuery().data
}
