import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { HydraMember } from '../../types/api'
import { FormErrors } from '../../types/misc'
import { BulkImportReferralCollectionItem } from '../../types/responses/referral-import'
import { RootState } from '../store'

export type ReferralsImportStep =
  | 'choose'
  | 'upload-historical'
  | 'upload-new'
  | 'resolve-import'

export type ReferralsImportItem =
  HydraMember<BulkImportReferralCollectionItem> & {
    refUUID: string
    importStatus: 'ready' | 'processing' | 'imported' | 'error'
    importErrors?: FormErrors
  }

export interface ReferralsImportState {
  open: boolean
  step: ReferralsImportStep
  currentImportItem: number
  importItems: ReferralsImportItem[]
}

const INITIAL_STATE: ReferralsImportState = {
  open: false,
  step: 'choose',
  currentImportItem: 0,
  importItems: [],
}

const referralsImport = createSlice({
  name: 'referralsImport',
  initialState: INITIAL_STATE,
  reducers: {
    setOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload
    },
    setStep(state, action: PayloadAction<ReferralsImportState['step']>) {
      state.step = action.payload
    },
    setImportItems(
      state,
      action: PayloadAction<ReferralsImportState['importItems']>,
    ) {
      state.importItems = action.payload
    },
    updateImportItem(state, action: PayloadAction<ReferralsImportItem>) {
      const item = state.importItems.find(
        (i) => i.refUUID === action.payload.refUUID,
      )
      if (item) {
        Object.assign(item, action.payload)
      }
    },
    removeImportItem(state, action: PayloadAction<string>) {
      state.importItems = state.importItems.filter(
        (i) => i.refUUID !== action.payload,
      )
    },
    updateImportItemStatus(
      state,
      action: PayloadAction<
        Pick<ReferralsImportItem, 'refUUID' | 'importStatus' | 'importErrors'>
      >,
    ) {
      const item = state.importItems.find(
        (i) => i.refUUID === action.payload.refUUID,
      )
      if (item) {
        item.importStatus = action.payload.importStatus
        item.importErrors = action.payload.importErrors
      }
    },
  },
})

export const referralsImportReducer = referralsImport.reducer

export const selectReferralsImport = (state: RootState) => state.referralsImport

export const {
  setOpen,
  setImportItems,
  updateImportItem,
  removeImportItem,
  setStep,
  updateImportItemStatus,
} = referralsImport.actions
