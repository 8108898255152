import { ErrorBoundary } from '@sentry/react'
import classNames from 'classnames'
import React, { Suspense } from 'react'
import { Modal } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

import { useRecommendedPartnerModal } from '../../../../hooks/useRecommendedPartnerModal'
import { RECOMMENDED_PARTNER_VIDEO_HEIGHT } from '../../constants'
import RecommendedPartnerModal from './RecommendedPartnerModal'

const RecommendedPartnerModalContainer: React.FC = () => {
  const recommendedPartnerModal = useRecommendedPartnerModal()

  if (!recommendedPartnerModal.params.recommendedPartnerId) {
    return null
  }

  return (
    <Modal
      show
      onHide={recommendedPartnerModal.hide}
      size="xl"
      contentClassName="pt-0 px-0"
      className={classNames(
        'RecommendedPartnerModal',
        `RecommendedPartnerModal__${recommendedPartnerModal.params.recommendedPartnerStep}`,
      )}
    >
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<LoadingSkeleton />}>
          <RecommendedPartnerModal />
        </Suspense>
      </ErrorBoundary>
    </Modal>
  )
}

const LoadingSkeleton: React.FC = () => {
  return (
    <Modal.Body>
      <Skeleton style={{ height: RECOMMENDED_PARTNER_VIDEO_HEIGHT }} />
    </Modal.Body>
  )
}

const ErrorFallback: React.FC = () => {
  const { hide } = useRecommendedPartnerModal()

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Unable to fetch details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Oops, it looks there was a problem loading the details for this vetted
          partner.
        </p>

        <p className="mb-0">
          Our team has been notified. Please try again later.
        </p>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-outline-secondary" onClick={hide}>
          Close
        </button>
      </Modal.Footer>
    </>
  )
}

export default RecommendedPartnerModalContainer
