import React from 'react'

import { useRecommendedPartnerModal } from '../../../hooks/useRecommendedPartnerModal'
import {
  getNextStep,
  getPreviousStep,
  makeWidthAndHeight,
} from '../../../lib/helpers/helperFunctions'
import recommendedPartnerService, {
  RECOMMENDED_PARTNER_STEP,
  RecommendedPartnerStep,
} from '../../../lib/services/recommendedPartnerService'
import watchVideoActiveIcon from '../components/RecommendedPartnerModal/icons/watch-video--active.svg'
import watchVideoInactiveIcon from '../components/RecommendedPartnerModal/icons/watch-video--inactive.svg'
import whatOurClientsSayActiveIcon from '../components/RecommendedPartnerModal/icons/what-our-clients-say--active.svg'
import whatOurClientsSayInactiveIcon from '../components/RecommendedPartnerModal/icons/what-our-clients-say--inactive.svg'
import whatProfessionalsSayActiveIcon from '../components/RecommendedPartnerModal/icons/what-professionals-say--active.svg'
import whatProfessionalsSayInactiveIcon from '../components/RecommendedPartnerModal/icons/what-professionals-say--inactive.svg'
import whereWeCanHelpActiveIcon from '../components/RecommendedPartnerModal/icons/where-we-can-help--active.svg'
import whereWeCanHelpInactiveIcon from '../components/RecommendedPartnerModal/icons/where-we-can-help--inactive.svg'
import workingWithYouActiveIcon from '../components/RecommendedPartnerModal/icons/working-with-you--active.svg'
import workingWithYouInactiveIcon from '../components/RecommendedPartnerModal/icons/working-with-you--inactive.svg'
import WatchVideo from '../components/RecommendedPartnerModal/steps/1_WatchVideo/WatchVideo'
import WhereWeCanHelp from '../components/RecommendedPartnerModal/steps/2_WhereWeCanHelp/WhereWeCanHelp'
import WhatOurClientsSay from '../components/RecommendedPartnerModal/steps/3_WhatOurClientsSay/WhatOurClientsSay'
import WhatProfessionalsSay from '../components/RecommendedPartnerModal/steps/4_WhatProfessionalsSay/WhatProfessionalsSay'
import WorkingWithYou from '../components/RecommendedPartnerModal/steps/5_WorkingWithYou/WorkingWithYou'
import { useRecommendedPartner } from './useRecommendedPartner'

export interface RecommendedPartnerStepElement {
  label: string
  step: RecommendedPartnerStep
  showAsTab: boolean
  element: React.ReactNode
  icon: {
    inactive: string
    active: string
    style: React.CSSProperties
  }
}

export function useRecommendedPartnerSteps() {
  const recommendedPartner = useRecommendedPartner()
  const { params, setStep } = useRecommendedPartnerModal()
  const recommendedPartnerStep = params.recommendedPartnerStep

  function buildStepElements(): RecommendedPartnerStepElement[] {
    const steps: RecommendedPartnerStepElement[] = []

    if (recommendedPartner.videoUrl) {
      steps.push({
        label: 'Watch video',
        step: RECOMMENDED_PARTNER_STEP.watchVideo,
        showAsTab: true,
        element: <WatchVideo />,
        icon: {
          inactive: watchVideoInactiveIcon,
          active: watchVideoActiveIcon,
          style: makeWidthAndHeight(16),
        },
      })
    }

    steps.push({
      label: 'Where we can help',
      step: RECOMMENDED_PARTNER_STEP.whereWeCanHelp,
      showAsTab: true,
      element: <WhereWeCanHelp />,
      icon: {
        inactive: whereWeCanHelpInactiveIcon,
        active: whereWeCanHelpActiveIcon,
        style: makeWidthAndHeight(28),
      },
    })

    if (recommendedPartner.clientTestimonials) {
      steps.push({
        label: 'What our clients say',
        step: RECOMMENDED_PARTNER_STEP.whatOurClientsSay,
        showAsTab: true,
        element: <WhatOurClientsSay />,
        icon: {
          inactive: whatOurClientsSayInactiveIcon,
          active: whatOurClientsSayActiveIcon,
          style: makeWidthAndHeight(18),
        },
      })
    }

    if (recommendedPartner.hasReviews) {
      steps.push({
        label: 'What professionals say',
        step: RECOMMENDED_PARTNER_STEP.whatProfessionalsSay,
        showAsTab: true,
        element: <WhatProfessionalsSay />,
        icon: {
          inactive: whatProfessionalsSayInactiveIcon,
          active: whatProfessionalsSayActiveIcon,
          style: makeWidthAndHeight(22),
        },
      })
    }

    if (recommendedPartner.workingWithYou) {
      steps.push({
        label: 'Working with you',
        step: RECOMMENDED_PARTNER_STEP.workingWithYou,
        showAsTab: true,
        element: <WorkingWithYou />,
        icon: {
          inactive: workingWithYouInactiveIcon,
          active: workingWithYouActiveIcon,
          style: makeWidthAndHeight(22),
        },
      })
    }

    return steps
  }

  const allStepElements = buildStepElements()
  const tabbedStepElements = allStepElements.filter((step) => step.showAsTab)
  const allSteps = allStepElements.map((step) => step.step)
  const tabbedSteps = tabbedStepElements.map((step) => step.step)

  function determineInitialStep() {
    if (
      recommendedPartnerService.isValidStep(
        recommendedPartner,
        recommendedPartnerStep,
      )
    ) {
      return recommendedPartnerStep
    }

    return recommendedPartner.videoUrl
      ? RECOMMENDED_PARTNER_STEP.watchVideo
      : RECOMMENDED_PARTNER_STEP.whereWeCanHelp
  }

  const initialStep = determineInitialStep()

  const currentStep = recommendedPartnerService.isValidStep(
    recommendedPartner,
    recommendedPartnerStep,
  )
    ? recommendedPartnerStep
    : (allSteps[0] as RecommendedPartnerStep)

  function goToStep(step: RecommendedPartnerStep) {
    setStep(step)
  }

  function goToNextStep() {
    const nextStep = getNextStep<RecommendedPartnerStep[]>(
      tabbedSteps,
      currentStep,
    )
    setStep(nextStep)
  }

  function goToPreviousStep() {
    const previousStep = getPreviousStep<RecommendedPartnerStep[]>(
      tabbedSteps,
      currentStep,
    )

    setStep(previousStep)
  }

  return {
    currentStep,
    goToStep,
    goToNextStep,
    goToPreviousStep,
    stepElements: allStepElements,
    tabbedStepElements,
    initialStep,
  }
}
