import React, { useEffect } from 'react'

import useAppSelector from '../../../../hooks/useAppSelector'
import { useCaptureErrorShownToUserEvent } from '../../../../hooks/useCaptureErrorShownToUserEvent'
import {
  COMMON_DATA_ERROR_MESSAGE,
  EMAIL_ADDRESSES,
} from '../../../../lib/constants'
import { selectSessionMetadata } from '../../../../redux/slices/session'
import CommonDataErrorMessage from '../../../utils/CommonDataErrorMessage'
import DevOnly from '../../../utils/DevOnly'
import ReloadButton from './ReloadButton'

const UnhandledErrorMessage: React.FC<{ error?: unknown }> = ({ error }) => {
  const isCommonDataError =
    error instanceof Error && error?.message.includes(COMMON_DATA_ERROR_MESSAGE)

  useCaptureErrorAfterTwoSeconds(error)

  return (
    <section className="position-relative">
      <h2>Oops.</h2>

      <ReloadButton />

      <p>
        Something seems to have gone wrong on our side. Our team has been
        notified.
      </p>

      <p>
        Try to refresh this page. If the problem persists, you can contact us at{' '}
        <a href={`mailto:${EMAIL_ADDRESSES.rqHello}?subject=Website error`}>
          {EMAIL_ADDRESSES.rqHello}
        </a>{' '}
        and we can help out!
      </p>

      <DevOnly>
        <div className="d-flex flex-column">
          {isCommonDataError && <CommonDataErrorMessage />}

          <ul>
            <li>
              <a
                href={`${import.meta.env.VITE_RQ_API_URL}/_profiler/latest`}
                target="_blank"
                rel="noreferrer"
              >
                View last API request
              </a>
            </li>

            <li>
              <a
                href={`${import.meta.env.VITE_RQ_API_URL}/_profiler/search?limit=10`}
                target="_blank"
                rel="noreferrer"
              >
                View last 10 API requests
              </a>
            </li>
          </ul>
        </div>
      </DevOnly>
    </section>
  )
}

function useCaptureErrorAfterTwoSeconds(error: unknown) {
  const sessionMetadata = useAppSelector(selectSessionMetadata)
  const captureErrorShownToUserEvent = useCaptureErrorShownToUserEvent()

  useEffect(() => {
    // Sometimes <UnhandledErrorMessage /> may render for a split second before
    // the user is taken to another route (e.g., when a session expires).
    // In such cases, we don't want to capture the error so let's wait for 2
    // seconds before capturing the error.
    const captureErrorEventTimer = setTimeout(
      () => captureErrorShownToUserEvent(error),
      2000,
    )

    // Clear the timer if the component unmounts
    return () => {
      clearTimeout(captureErrorEventTimer)
    }
  }, [captureErrorShownToUserEvent, error, sessionMetadata])
}

export default UnhandledErrorMessage
