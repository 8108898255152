import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faCalendar,
  faCheck,
  faLocationDot,
  faPen,
  faPhone,
  faUser,
  faUserGroup,
} from '@rq-ratings/pro-light-svg-icons'
import classNames from 'classnames'
import React from 'react'

import {
  DATE_FORMATS,
  THEME_PALETTE_DARK,
} from '../../../../../../lib/constants'
import { formatDate } from '../../../../../../lib/helpers/helperFunctions'
import { ReviewItem } from '../../../../../../types/responses/review'

interface Props {
  review: ReviewItem
}

interface ReviewStat {
  icon: IconProp | string
  stat: string
}

const ReviewStats: React.FC<Props> = ({ review }) => {
  const stats: ReviewStat[] = []

  if (review.reviewer.name) {
    stats.push({
      icon: faUser,
      stat: review.reviewer.name + ', ' + review.reviewer.title,
    })
  }

  if (review.reviewer.firm) {
    stats.push({
      icon: faBriefcase,
      stat: review.reviewer.firm,
    })
  }

  if (review.reviewer.location) {
    stats.push({
      icon: faLocationDot,
      stat: review.reviewer.location,
    })
  }

  if (review.reviewer.numEmployees) {
    stats.push({
      icon: faUserGroup,
      stat: review.reviewer.numEmployees,
    })
  }

  if (review.type) {
    stats.push({
      icon: review.type === 'written' ? faPen : faPhone,
      stat: review.type === 'written' ? 'Written review' : 'Phone interview',
    })
  }

  if (review.numClientsReferred) {
    stats.push({
      icon: review.numClientsReferred || '',
      stat: 'Clients referred',
    })
  }

  if (review.numYearsCollaborating) {
    stats.push({
      icon: review.numYearsCollaborating || '',
      stat: 'Years collaborating',
    })
  }

  if (review.createdAt) {
    stats.push({
      icon: faCalendar,
      stat: `Review left on ${formatDate(
        review.createdAt,
        DATE_FORMATS.MONTH_DAY_YEAR,
      )}`,
    })
  }

  if (review.lastConfirmedAt) {
    stats.push({
      icon: faCheck,
      stat: `Last confirmed on ${formatDate(
        review.lastConfirmedAt,
        DATE_FORMATS.MONTH_DAY_YEAR,
      )}`,
    })
  }

  return (
    <aside
      className="py-4 px-3"
      style={{ backgroundColor: THEME_PALETTE_DARK.info }}
    >
      <ul className="m-0 p-0">
        {stats.map((stat, index) => (
          <li
            key={index}
            className={classNames('d-flex align-items-center mb-3', {
              'mb-auto': index === stats.length - 1,
            })}
          >
            <div
              className="text-primary text-center d-flex align-items-center justify-content-center"
              style={{ marginRight: '10px', width: '20px' }}
            >
              {typeof stat.icon === 'string' ? (
                <div
                  className="fw-bold"
                  style={{ fontSize: stat.icon.length > 3 ? '12px' : '15px' }}
                >
                  {stat.icon}
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={stat.icon}
                  style={{ fontSize: '17px' }}
                />
              )}
            </div>
            <div>{stat.stat}</div>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default ReviewStats
