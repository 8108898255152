import { useSearchParams } from 'react-router-dom'

import {
  RECOMMENDED_PARTNER_STEP,
  RecommendedPartnerStep,
} from '../lib/services/recommendedPartnerService'

export const RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS = {
  recommendedPartnerId: 'recommendedPartnerId',
  recommendedPartnerStep: 'recommendedPartnerStep',
}

export function useRecommendedPartnerModal() {
  const [searchParams, setSearchParams] = useSearchParams()

  const params = {
    recommendedPartnerId: searchParams.get(
      RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerId,
    ),
    recommendedPartnerStep: searchParams.get(
      RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerStep,
    ),
  }

  function show(options: {
    recommendedPartnerId: number
    step?: RecommendedPartnerStep
  }) {
    const recommendedPartnerId = options.recommendedPartnerId
    const step = options.step || RECOMMENDED_PARTNER_STEP.watchVideo

    console.log('show', recommendedPartnerId, step)

    searchParams.set(
      RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerId,
      recommendedPartnerId.toString(),
    )

    searchParams.set(
      RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerStep,
      step.toString(),
    )

    setSearchParams(searchParams)
  }

  function hide() {
    searchParams.delete(
      RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerId,
    )
    searchParams.delete(
      RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerStep,
    )
    setSearchParams(searchParams)
  }

  function setStep(step: string | null) {
    if (step) {
      searchParams.set(
        RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerStep,
        step,
      )
    } else {
      searchParams.delete(
        RECOMMENDED_PARTNER_MODAL_SEARCH_PARAMS.recommendedPartnerStep,
      )
    }

    setSearchParams(searchParams)
  }

  return {
    params,
    show,
    hide,
    setStep,
  }
}
