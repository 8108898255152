import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileLines,
  faGear,
  faPager,
  faUsers,
} from '@rq-ratings/pro-light-svg-icons'
import { faQuestionCircle } from '@rq-ratings/pro-regular-svg-icons'
import truncate from 'lodash/truncate'
import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { PlusSquare, User } from 'react-feather'
import { Link } from 'react-router-dom'
import invariant from 'tiny-invariant'

import useAppSelector from '../../../../../hooks/useAppSelector'
import { useAuth } from '../../../../../hooks/useAuth'
import { useCurrentUserOrFail } from '../../../../../hooks/useCurrentUserOrFail'
import { useIsMinWidthSm } from '../../../../../hooks/useIsMinWidthSm'
import {
  COMPANY_IDENTIFIER,
  COMPANY_TYPE_CODE,
} from '../../../../../lib/constants'
import { isDevelopment } from '../../../../../lib/helpers/envHelpers'
import { getFrnTradingNameCode } from '../../../../../lib/helpers/frnHelpers'
import { bool2Human } from '../../../../../lib/helpers/helperFunctions'
import { ROUTES } from '../../../../../lib/routes'
import authService from '../../../../../lib/services/authService'
import hubspotChatService from '../../../../../lib/services/hubspotChatService'
import routeService from '../../../../../lib/services/routeService'
import { selectOnboardingChecklist } from '../../../../../redux/slices/onboardingChecklist'
import {
  selectCompanies,
  selectCurrentCompany,
  selectHasClientAccount,
  selectHasCompanyAccount,
  selectIsAuthenticatedClient,
  selectIsCompanyUser,
} from '../../../../../redux/slices/session'
import { selectIsUpdatingContext } from '../../../../../redux/slices/session'
import UserClients from './UserClients'
import UserCompanies from './UserCompanies'

const AccountDropdown: React.FC = () => {
  const currentUser = useCurrentUserOrFail()
  const currentCompany = useAppSelector(selectCurrentCompany)
  const companies = useAppSelector(selectCompanies)
  const isAuthenticatedClient = useAppSelector(selectIsAuthenticatedClient)
  const isCompanyUser = useAppSelector(selectIsCompanyUser)
  const hasCompanyAccount = useAppSelector(selectHasCompanyAccount)
  const hasClientAccount = useAppSelector(selectHasClientAccount)
  const isSwitchingCompany = useAppSelector(selectIsUpdatingContext)
  const onboardingChecklist = useAppSelector(selectOnboardingChecklist)
  const isMinWidthSm = useIsMinWidthSm()
  const { signOut } = useAuth()

  function renderCurrentCompanyName() {
    if (!currentCompany) {
      return ''
    }

    if (isSwitchingCompany) {
      return '(Updating...)'
    }

    const name = currentCompany.presentationName
    invariant(name, `Company ${currentCompany['@id']} has no name`)

    const truncatedName =
      name.length > 12 ? name.substring(0, 11) + '...' : name
    return `(${truncatedName})`
  }

  function buildCompanyTooltip() {
    if (!isDevelopment()) {
      return undefined
    }

    if (!currentCompany) {
      return undefined
    }

    const info: string[] = [
      `Company ID: ${currentCompany.id}`,
      `Company type: ${currentCompany.companyType.code}`,
    ]

    if (currentCompany.companyType.code === COMPANY_TYPE_CODE.accountant) {
      info.push(
        `ICAEW-regulated: ${bool2Human(currentCompany.isRegulatedByIcaew)}`,
      )
    }

    return info.join('\n')
  }

  return (
    <div className="d-sm-inline-block">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link" role="button">
          {currentUser?.avatar && (
            <img
              src={currentUser.avatar}
              className="avatar img-fluid rounded-circle me-1"
              referrerPolicy="no-referrer"
              alt={currentUser.fullName}
            />
          )}

          <span
            className={currentUser.impersonated ? 'text-danger' : 'text-dark'}
          >
            <span
              title={isDevelopment() ? `User ID: ${currentUser.id}` : undefined}
            >
              {truncate(currentUser.fullName, { length: 20 })}{' '}
            </span>

            <span className="muted text-sm" title={buildCompanyTooltip()}>
              {renderCurrentCompanyName()}

              {isAuthenticatedClient && hasCompanyAccount && (
                <span>(Client)</span>
              )}
            </span>
          </span>
        </Dropdown.Toggle>
      </span>

      <Dropdown.Menu
        align="end"
        style={{
          maxHeight: '740px',
          overflowY: 'auto',
          width: isMinWidthSm ? '' : '100%',
          zIndex: 99999999,
        }}
      >
        {hasCompanyAccount && <UserCompanies />}

        {hasClientAccount && <UserClients />}

        {isCompanyUser && (
          <>
            <Dropdown.Item as={Link} to={ROUTES.addCompany}>
              <PlusSquare size={18} className="align-middle me-2" />
              {companies.length > 0 ? 'Add another Company' : 'Add Company'}
            </Dropdown.Item>

            <Dropdown.Divider />
          </>
        )}

        <Dropdown.Item
          as={Link}
          to={authService.getAccountManagementUrl()}
          target="_blank"
        >
          <User size={18} className="align-middle me-2" />
          Profile
        </Dropdown.Item>

        {currentCompany && (
          <Dropdown.Item as={Link} to={ROUTES.preferences}>
            <FontAwesomeIcon
              icon={faGear}
              className="align-middle me-2"
              style={{ fontSize: '15px' }}
            />
            Preferences
          </Dropdown.Item>
        )}

        {currentCompany && (
          <Dropdown.Item as={Link} to={ROUTES.completeRegistration}>
            <FontAwesomeIcon
              icon={faFileLines}
              className="align-middle me-2"
              style={{ fontSize: '15px' }}
            />
            Tell us about {currentCompany.presentationName}
          </Dropdown.Item>
        )}

        {currentCompany?.companyType.fca && (
          <Dropdown.Item
            as={Link}
            to={routeService.companyProfile({
              identifier: getFrnTradingNameCode(
                currentCompany.frn.toString(),
                currentCompany.frnTradingNameId,
              ),
              identifierType: COMPANY_IDENTIFIER.frn,
            })}
          >
            <FontAwesomeIcon icon={faPager} className="align-middle me-2" />
            View firm RQ profile
          </Dropdown.Item>
        )}

        {!isAuthenticatedClient && (
          <Dropdown.Item as={Link} to={ROUTES.manageUsers}>
            <FontAwesomeIcon icon={faUsers} className="align-middle me-2" />
            Manage Users
          </Dropdown.Item>
        )}

        {isCompanyUser && onboardingChecklist && (
          <Dropdown.Item as={Link} to={ROUTES.gettingStarted}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="align-middle me-2 text-muted"
            />
            Getting started with RQ
          </Dropdown.Item>
        )}

        {isCompanyUser && (
          <Dropdown.Item
            href={ROUTES.walkthrough}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="align-middle me-2 text-muted"
            />
            RQ Walkthrough
          </Dropdown.Item>
        )}

        <Dropdown.Divider />

        <Dropdown.Item
          onClick={() =>
            hubspotChatService.openChat({
              currentUser,
              currentCompany,
            })
          }
        >
          Help
        </Dropdown.Item>

        {!isAuthenticatedClient && (
          <Dropdown.Item as={Link} to={ROUTES.bookMeeting}>
            Book a call with our team
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={() => signOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </div>
  )
}

export default AccountDropdown
