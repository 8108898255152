import capitalize from 'lodash/capitalize'
import React from 'react'

import {
  CompassInput,
  MortgageInput,
} from '../../../components/flows/CompassFlow/utils/types'
import compassReportService from './compassReportService'
import compassService from './compassService'

class CompassQuestionService {
  getQuestions(input: CompassInput) {
    const personalSituation = input.aboutYourClient?.user_private_situation
    const partnerOrSpouseTerm =
      compassService.getPartnerOrSpouseTerm(personalSituation)
    const businessAssetsTerm = compassReportService.getBusinessAssetsTerm(
      input.aboutYourClient?.user_professional_situation,
    )
    const shouldShowPartnerQuestions =
      this.shouldShowPartnerQuestions(personalSituation)

    const capitalizedPartnerOrSpouseTerm = capitalize(partnerOrSpouseTerm)

    // Step 1
    const aboutYourClient = {
      name: 'Name',
      dateOfBirth: 'Date of birth',
      email: 'Email address',
      personalSituation:
        'Which of the following best describes your personal situation?',
      professionalSituation:
        'Which of the following best describes your current professional situation?',
      partnerName: `${capitalizedPartnerOrSpouseTerm} - Name`,
      partnerDateOfBirth: `${capitalizedPartnerOrSpouseTerm} - Date of birth`,
      partnerProfessionalSituation: (
        <>
          Which of the following best describes your {partnerOrSpouseTerm}'s{' '}
          <strong>professional</strong> situation?
        </>
      ),
    }

    // Step 2
    const personalAssets = {
      jointCurrentSavings: (
        <>
          What is the approximate current balance of any <strong>joint</strong>{' '}
          <span className="font-weight-bold text-decoration-underline">
            cash
          </span>{' '}
          savings?
        </>
      ),
      jointCurrentInvestments: (
        <>
          What is the approximate current value of any <strong>joint</strong>{' '}
          non-pension investments?
        </>
      ),
      jointHasPropertyQuestion: (
        <>
          Do you own any property <strong>jointly</strong>?
        </>
      ),
      jointHasRentalPropertyQuestion: (
        <>
          Do you own any rental property <strong>jointly</strong>?
        </>
      ),

      currentSavings: (
        <>
          What is the approximate current balance of all{' '}
          <span className="font-weight-bold text-decoration-underline">
            cash
          </span>{' '}
          savings (those held in your name only)? *
        </>
      ),
      currentPensions:
        'What is the approximate current value of your pensions?',
      currentInvestments:
        'What is the approximate current value of your non-pension investments? (Eg, ISA. Please do not include value of any property)',
      hasDefinedBenefitScheme:
        "Do you have any entitlement to a Pension from a 'Defined Benefit' scheme in the future?",
      definedBenefitCommenceDate:
        'When do your pension benefits become payable?',
      definedBenefitMemberType:
        "Are you an 'active' or 'deferred' member of this 'Defined Benefit' scheme?",
      definedBenefitAnnualGrossBenefit:
        'How much income do you expect to receive each year?',
      definedBenefitIsUnknown: "I don't know",
      hasPropertyQuestion: shouldShowPartnerQuestions
        ? 'Do you own any property (not jointly held with your partner)?'
        : 'Do you own any property?',
      propertyApproximateValue: 'Approximate value of property',
      hasRentalPropertyQuestion: shouldShowPartnerQuestions
        ? 'Do you own any rental property (not jointly held with your partner)?'
        : 'Do you own any rental property?',
      propertyName: 'Property name',
      propertyEstimatedValue:
        'What is the estimated current value of this property?',
      hasOutstandingMortgageOnProperty:
        'Do you have an outstanding mortgage on this property?',
      mortgageType: 'Mortgage type',
      mortgageOutstandingBalance: 'Approximate outstanding mortgage balance',
      mortgageInterestRate: 'What rate of interest are you currently paying?',
      mortgageCapitalRepaymentDate: (
        mortgageType?: MortgageInput['mortgage_type'],
      ) => {
        return mortgageType === 'Interest only'
          ? 'When must the capital be repaid?'
          : `When will your mortgage be fully repaid?`
      },
      mortgageTrackerOrFixed:
        'Do you have a tracker, or a fixed rate mortgage?',
      mortgageTermEndDate: (
        trackerOrFixed: MortgageInput['fixed_rate_mortgage'],
      ) => {
        return trackerOrFixed === 'Tracker'
          ? 'When does your tracker rate period end?'
          : 'When does your fixed rate period end?'
      },
      hadMortgageBroker:
        'Did you work with a mortgage broker / adviser when arranging this mortgage?',
      mortgageBrokerName:
        "You can search for your mortgage broker / adviser here, if you'd like to...",
    }

    // Step 3
    const businessAssets = {
      wantToSellShares: (companyName?: string) => {
        const companyNameText = companyName ? ` in ${companyName} ` : ' '

        return `Do you think you will sell some or all of your ${businessAssetsTerm}${companyNameText}at some time in the future?`
      },
      sellYear: `When do you think you will, or might like to sell your ${businessAssetsTerm}? *`,
      expectedValue: `What do you expect your ${businessAssetsTerm} will be worth at the point of your exit / sale? *`,
      businessPlanning: (
        <>
          How much <strong>business</strong> planning have you done so far in
          preparation for your exit / sale?
        </>
      ),
      personalPlanning: (
        <>
          How much <strong>personal</strong> planning have you done so far in
          preparation for your exit / sale?
        </>
      ),
      confidence: 'How confident are you in your estimated sale value? *',
      isSoleTrader: 'Are you a sole trader?',
    }

    // Step 4
    const liabilities = {
      hasFinancialDependants: 'Do you have any financial dependants?',
      financialDependantDateOfBirth: 'Your financial dependant’s date of birth',
      hasPersonalLoans: (
        <>
          Do you have any other personal loan balances (that you don't clear at
          the end of each month)?
        </>
      ),
      personalLoanBalance: 'Approximate outstanding balance',
      personalLoanInterestRate:
        'What rate of interest are you currently paying?',
      personalLoanRepaymentDate: 'When will your loan be fully repaid?',
      hasCreditCard: (
        <>
          Do you have any other credit card balance (that you don't clear at the
          end of each month)?
        </>
      ),
      creditCardBalance: 'Approximate outstanding balance',
      familyProtectionOptions: (
        <>
          With regard to protecting <strong>your / your family's</strong>{' '}
          financial position... Do you have:
        </>
      ),
      willLastUpdatedDate: 'When was your Will last updated?',
      professionalProtectionOptions: (
        <>
          With regard to protecting{' '}
          <strong>your business and its senior people</strong>... Do you have:
        </>
      ),
    }

    // Step 5
    const income = {
      annualSalary: (
        <>
          <strong>Annual</strong> salary
        </>
      ),
      annualBonus: (
        <>
          <strong>Annual</strong> bonus
        </>
      ),
      annualCommission: (
        <>
          <strong>Annual</strong> commission
        </>
      ),
      annualDividends: (
        <>
          <strong>Annual</strong> dividends
        </>
      ),
      annualRentalIncome: (
        <>
          <strong>Annual</strong> rental income (<strong>before</strong> any
          mortgage payments)
        </>
      ),

      jointMonthlyOutgoings: (
        <>
          <strong>
            Excluding any personal pension contributions, mortgage payments, or
            personal loan payments
          </strong>
          , what is your approximate level of <strong>jointly monthly</strong>{' '}
          outgoings?
        </>
      ),

      monthlyOutgoings: (
        <>
          <strong>
            Excluding any personal pension contributions, mortgage payments, or
            personal loan payments
          </strong>
          , what is your approximate level of <strong>monthly</strong>{' '}
          outgoings?{' '}
        </>
      ),

      monthlySpentConfidence: (
        <>
          Leaving to one side the <em>level</em> of your monthly expenditure,
          how confident do you feel that you truly know{' '}
          <strong>where your money goes</strong> each month?
        </>
      ),

      hasPensionContribution:
        'Do you, or your employer/business, currently make pension contributions?',
      pensionContributionEmployeeStake: (
        <>
          How much do <strong>you</strong> contribute to your pension each{' '}
          <strong>month</strong>?
          <div className="text-sm text-muted mb-1">
            Please provide Gross (pre-tax) values
          </div>
        </>
      ),
      pensionContributionEmployerStake: (
        <>
          How much does your <strong>employer/business</strong> contribute to
          your pension each <strong>month</strong>?
          <div className="text-sm  text-muted mb-1">
            Please provide Gross (pre-tax) values
          </div>
        </>
      ),
    }

    const interests = {
      financialIndependenceAge: (options: {
        firstName: string
        compassInput: CompassInput
      }) => {
        const { firstName, compassInput } = options

        return compassQuestionsService.shouldShowPartnerQuestions(
          compassInput.aboutYourClient?.user_private_situation,
        ) ? (
          <>
            <strong>{firstName}</strong>, at what age would you like to achieve
            financial independence?
          </>
        ) : (
          <>At what age would you like to achieve financial independence?</>
        )
      },
      interestOptions:
        'Which of the following areas are of concern or interest to you?',
      hasAdditionalOutflows: (
        <>
          Aside from long-term financial security and independence, and outside
          of your regular monthly expenditure, do you have any other{' '}
          <strong>specific goals</strong> that require money – and perhaps also
          planning – to achieve?
        </>
      ),
      outflowCategory: 'Goal or objective',
      outflowObjective: 'Please specify your goal or objective',
      outflowCosts: 'Financial implication',
      outflowFrequency: 'What is the frequency of this expenditure?',
      outflowStartDate: 'Start date',
      outflowEndDate: 'End date',
      financialWellBeingScore: (
        <>
          Consider your current financial well-being. Scoring yourself out of
          10, how do you feel currently about your financial well-being compared
          to where you would like it to be?
        </>
      ),
      hasFinancialAdvisor:
        'Do you have a relationship with a financial planner / wealth manager currently?',
      financialAdvisor: 'Who is your financial planner / wealth manager?',
      durationWithFinancialAdvisor:
        'How long have you worked with your financial planner / wealth manager?',
      otherIssues: "Anything you'd like to add or emphasize?",
    }

    return {
      aboutYourClient,
      personalAssets,
      businessAssets,
      liabilities,
      income,
      interests,
    }
  }

  shouldShowPartnerQuestions(personalSituation?: string) {
    return compassService.hasSharedFinances(personalSituation)
  }
}

const compassQuestionsService = new CompassQuestionService()

export default compassQuestionsService
