import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement>

const SectionContainer: React.FC<Props> = ({ className, style, ...props }) => (
  <section
    className={classNames(className)}
    style={{ maxWidth: '640px', ...style }}
    {...props}
  />
)

export default SectionContainer
