import { AboutYourClientInput } from '../../utils/types'

export const AboutYourClientInputFields: Record<
  keyof AboutYourClientInput,
  keyof AboutYourClientInput
> = {
  existing_client: 'existing_client',
  user_name: 'user_name',
  user_name_first: 'user_name_first',
  user_name_last: 'user_name_last',
  user_date_of_birth: 'user_date_of_birth',
  user_email: 'user_email',
  user_private_situation: 'user_private_situation',
  user_professional_situation: 'user_professional_situation',
}

export const PERSONAL_SITUATION_MARRIED = 'Married'
export const PERSONAL_SITUATION_RELATIONSHIP_WITH_SHARED_FINANCES =
  'In a relationship with shared finances'

export const WITH_PARTNER_PERSONAL_SITUATIONS = [
  PERSONAL_SITUATION_MARRIED,
  PERSONAL_SITUATION_RELATIONSHIP_WITH_SHARED_FINANCES,
]

export const COMPASS_DATE_OF_BIRTH_FIELD_MAX_WIDTH = 130
