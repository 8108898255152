import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  html: string
}

const HtmlContent: React.FC<Props> = ({ html, className, ...props }) => (
  <div
    dangerouslySetInnerHTML={{ __html: html }}
    className={classNames('HtmlContent', className)}
    {...props}
  />
)

export default HtmlContent
