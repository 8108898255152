import React from 'react'

import CompanyReviews from '../../../../../../components/misc/CompanyReviews/CompanyReviews'
import { useRecommendedPartner } from '../../../../hooks/useRecommendedPartner'
import SectionHeading from '../../components/SectionHeading'

const WhatProfessionalsSay: React.FC = () => {
  const companyId = useRecommendedPartner().company.id

  return (
    <>
      <SectionHeading className="mb-3">
        What other professionals say about us
      </SectionHeading>

      <CompanyReviews companyId={companyId} />
    </>
  )
}

export default WhatProfessionalsSay
