import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@rq-ratings/pro-regular-svg-icons'
import classNames from 'classnames'
import React from 'react'
import { ButtonProps } from 'react-bootstrap'

import ActionButton from '../../../../../components/misc/ActionButton'
import { useAcceptRecommendedPartnerMutation } from '../../../hooks/useAcceptRecommendPartnerMutation'
import { useRecommendedPartner } from '../../../hooks/useRecommendedPartner'

type Props = Omit<ButtonProps, 'children'>

const AddToReferralPartnersButton: React.FC<Props> = ({
  className,
  size = 'lg',
  ...props
}) => {
  const partner = useRecommendedPartner()
  const acceptMutation = useAcceptRecommendedPartnerMutation()

  return (
    <ActionButton
      isProcessing={acceptMutation.isPending}
      isProcessingText="Adding to referral partners"
      onClick={() =>
        acceptMutation.mutate({
          partnerId: partner.id,
          partnerName: partner.company.presentationName,
        })
      }
      className={classNames(
        'd-flex justify-content-center align-items-center gap-2 btn-fluid',
        className,
      )}
      size={size}
      {...props}
    >
      <FontAwesomeIcon icon={faPlus} />
      Add to referral partners
    </ActionButton>
  )
}

export default AddToReferralPartnersButton
