import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { useHoverDirty, useKeyPressEvent } from 'react-use'

import VideoEmbed from '../../../../../../components/misc/VideoEmbed'
import { RECOMMENDED_PARTNER_VIDEO_HEIGHT } from '../../../../constants'
import { useRecommendedPartner } from '../../../../hooks/useRecommendedPartner'
import { useRecommendedPartnerSteps } from '../../../../hooks/useRecommendedPartnerSteps'

const WatchVideo: React.FC = () => {
  const recommendedPartner = useRecommendedPartner()
  const [isPlayingVideo, setIsPlayingVideo] = React.useState<boolean>()
  const { goToNextStep } = useRecommendedPartnerSteps()
  const videoThumbnailRef = useRef<HTMLDivElement | null>(null)
  const isHoveringOverThumbnail = useHoverDirty(videoThumbnailRef)

  // Play video on spacebar press
  useKeyPressEvent(' ', () => {
    if (!isPlayingVideo) {
      setIsPlayingVideo(true)
    }
  })

  if (!recommendedPartner.videoUrl) {
    goToNextStep()
    return null
  }

  if (isPlayingVideo || !recommendedPartner.videoThumbnailFileUrl) {
    return (
      <>
        <VideoEmbed
          videoUrl={recommendedPartner.videoUrl}
          height={RECOMMENDED_PARTNER_VIDEO_HEIGHT}
          autoplay={isPlayingVideo}
          style={{ borderRadius: '10px' }}
        />
      </>
    )
  }

  const thumbnailImageUrl = recommendedPartner.videoThumbnailFileUrl

  return (
    <div
      ref={videoThumbnailRef}
      className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
      style={{
        backgroundImage: `url(${thumbnailImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '10px',
        height: RECOMMENDED_PARTNER_VIDEO_HEIGHT,
        opacity: isHoveringOverThumbnail ? '0.95' : 1,
      }}
    >
      <div
        className="position-relative d-flex flex-column gap-4 justify-content-center align-items-center text-white w-100 h-100 px-4"
        onClick={() => setIsPlayingVideo(true)}
      >
        <FontAwesomeIcon icon={faPlay} style={{ fontSize: '70px' }} />

        <div
          className="font-weight-bold text-white text-center"
          style={{ fontSize: '26px' }}
        >
          {recommendedPartner.company.presentationName}
        </div>
      </div>
    </div>
  )
}

export default WatchVideo
