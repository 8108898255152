import React from 'react'
import Pagination from 'react-js-pagination'

interface Props {
  activePage: number
  totalItemsCount: number
  numItemsPerPage: number
  onChange: (newPage: number) => void
}

const TablePagination: React.FC<Props> = ({
  activePage,
  totalItemsCount,
  numItemsPerPage,
  onChange,
}) => (
  <>
    <Pagination
      innerClass="pagination justify-content-center flex-wrap"
      activePage={activePage}
      itemsCountPerPage={numItemsPerPage}
      totalItemsCount={totalItemsCount}
      itemClass="page-item"
      linkClass="page-link"
      pageRangeDisplayed={10}
      onChange={onChange}
    />
  </>
)

export default TablePagination
