import { FormikHelpers } from 'formik'
import * as yup from 'yup'

import {
  hasBothFirstAndLastName,
  replaceObjectKeys,
  setFieldErrors,
} from '../../../../../../lib/helpers/helperFunctions'
import { yupSchemas } from '../../../../../../lib/helpers/yupSchemas'
import constraintViolationService from '../../../../../../lib/services/constraintViolationService'
import { ConstraintViolationListError } from '../../../../../../types/api'
import { ClientEntity } from '../../../../../../types/entities'
import { EXPECTED_AGE_OF_DEATH } from '../../../utils/constants'
import { formatApiErrors } from '../../../utils/helpers'
import { AboutYourClientInput } from '../../../utils/types'
import { AboutYourClientInputFields } from '../constants'

export function getInitialValues(
  previousData?: AboutYourClientInput,
): AboutYourClientInput {
  const userFirstName = previousData?.user_name_first || ''
  const userLastName = previousData?.user_name_last || ''

  function getUserFullName() {
    if (previousData?.user_name) {
      return previousData.user_name
    }

    return userFirstName || userLastName
      ? `${userFirstName} ${userLastName}`
      : ''
  }

  return {
    existing_client: previousData?.existing_client,
    user_name: getUserFullName(),
    user_name_first: userFirstName,
    user_name_last: userLastName,
    user_date_of_birth: previousData?.user_date_of_birth,
    user_email: previousData?.user_email || '',
    user_private_situation: previousData?.user_private_situation,
    user_professional_situation:
      previousData?.user_professional_situation || [],
  }
}

export function getValidationSchema() {
  return yup.object({
    [AboutYourClientInputFields.user_name]: yup
      .string()
      .required('Name is a required field')
      .test(
        'is-valid-full-name',
        'Please provide both a first and last name',
        hasBothFirstAndLastName,
      ),
    [AboutYourClientInputFields.user_date_of_birth]: yupSchemas
      .dateOfBirth({ minAge: 12, maxAge: EXPECTED_AGE_OF_DEATH - 1 })
      .required(),
    [AboutYourClientInputFields.user_email]: yup.string().required().email(),
    [AboutYourClientInputFields.user_professional_situation]: yup
      .array()
      .of(yup.string())
      .min(1),
  })
}

export function handleCreateOrUpdateClientError(
  errors: ConstraintViolationListError,
  formikHelpers: FormikHelpers<AboutYourClientInput>,
) {
  const apiErrors = constraintViolationService.formatErrors(errors)
  const formattedErrors = formatApiErrors(apiErrors)

  // Map the field names for the Client entity to the field names we
  // use in the form.
  const keyReplacements: Partial<
    Record<keyof ClientEntity, keyof AboutYourClientInput>
  > = {
    email: AboutYourClientInputFields.user_email,
    firstName: AboutYourClientInputFields.user_name_first,
    lastName: AboutYourClientInputFields.user_name_last,
    dateOfBirth: AboutYourClientInputFields.user_date_of_birth,
  }

  const fieldErrors = replaceObjectKeys(formattedErrors, keyReplacements)

  setFieldErrors(fieldErrors, formikHelpers)
}
