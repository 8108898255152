import classNames from 'classnames'
import React, { useRef } from 'react'
import { useHoverDirty } from 'react-use'

import { useIsMinWidthMd } from '../../../../../hooks/useIsMinWidthMd'
import {
  RecommendedPartnerStepElement,
  useRecommendedPartnerSteps,
} from '../../../hooks/useRecommendedPartnerSteps'

interface Props {
  stepElement: RecommendedPartnerStepElement
  index: number
}

const RecommendedPartnerTabLink: React.FC<Props> = ({ stepElement, index }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isHovered = useHoverDirty(containerRef)
  const { tabbedStepElements, currentStep, goToStep } =
    useRecommendedPartnerSteps()
  const isMinWidthMd = useIsMinWidthMd()
  const isActive = stepElement.step === currentStep
  const isLastTab = index === tabbedStepElements.length - 1
  const { label, icon, step } = stepElement

  const isHoveredOrActive = isHovered || isActive

  return (
    <div
      ref={containerRef}
      className={classNames(
        {
          'border-end': !isLastTab,
        },
        'd-flex flex-row flex-md-column align-items-center cursor-pointer border-bottom',
      )}
      style={{
        backgroundColor: isActive ? 'white' : '#F5F5F5',
        flex: 1,
        padding: isMinWidthMd ? '8px 0' : '7px 6px',
      }}
      onClick={() => goToStep(step)}
    >
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: '28px', height: '28px' }}
      >
        <img
          src={isHoveredOrActive ? icon.active : icon.inactive}
          className="img-fluid"
          style={icon.style}
          alt={label}
        />
      </div>

      <div
        className={classNames(
          'font-weight-600 text-md-center px-2',
          isHoveredOrActive ? 'text-primary' : undefined,
        )}
        style={{ fontSize: '14px', minWidth: '170px' }}
      >
        {label}
      </div>
    </div>
  )
}

export default RecommendedPartnerTabLink
