import { PartnerDetailsInput } from '../../utils/types'

export const PartnerDetailsInputFields: Record<
  keyof PartnerDetailsInput,
  keyof PartnerDetailsInput
> = {
  partner_name: 'partner_name',
  partner_name_first: 'partner_name_first',
  partner_name_last: 'partner_name_last',
  partner_date_of_birth: 'partner_date_of_birth',
  partner_professional_situation: 'partner_professional_situation',
}
