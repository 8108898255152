import classNames from 'classnames'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

type Props = Omit<React.HTMLAttributes<HTMLTableElement>, 'children'> & {
  rows: number
  columns: number
  columnWidth?: number | number[]
}

const SkeletonTable: React.FC<Props> = ({
  rows,
  columns,
  columnWidth,
  className,
  ...props
}) => {
  function determineColumnWidth(columnIndex: number): number | undefined {
    if (!columnWidth) {
      return undefined
    }

    if (Array.isArray(columnWidth)) {
      return columnWidth[columnIndex]
    }

    return columnWidth
  }

  function makeRows(rowCount: number, columnCount: number) {
    return new Array(rowCount).fill('x').map((_, rowIndex) => (
      <tr key={rowIndex}>
        {new Array(columnCount).fill('x').map((_, columnIndex) => {
          return (
            <td
              key={columnIndex}
              style={{ width: determineColumnWidth(columnIndex) }}
            >
              <Skeleton />
            </td>
          )
        })}
      </tr>
    ))
  }

  return (
    <table
      {...props}
      className={classNames('table table-responsive', className)}
    >
      <tbody>{makeRows(rows, columns)}</tbody>
    </table>
  )
}

export default SkeletonTable
