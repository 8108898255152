import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@rq-ratings/pro-light-svg-icons'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async'
import { useMount } from 'react-use'

import Image from '../../../../components/misc/Image'
import { useIsMinWidthMd } from '../../../../hooks/useIsMinWidthMd'
import { useRecommendedPartnerModal } from '../../../../hooks/useRecommendedPartnerModal'
import { RecommendedPartnerStep } from '../../../../lib/services/recommendedPartnerService'
import { useRecommendedPartnerQuery } from '../../hooks/useRecommendedPartner'
import { useRecommendedPartnerSteps } from '../../hooks/useRecommendedPartnerSteps'
import AddToReferralPartnersButton from './components/AddToReferralPartnersButton'
import RecommendedPartnerTabLinks from './components/RecommendedPartnerTabLinks'
import WatchVideo from './steps/1_WatchVideo/WatchVideo'
import WhereWeCanHelp from './steps/2_WhereWeCanHelp/WhereWeCanHelp'
import WhatOurClientsSay from './steps/3_WhatOurClientsSay/WhatOurClientsSay'
import WhatProfessionalsSay from './steps/4_WhatProfessionalsSay/WhatProfessionalsSay'
import WorkingWithYou from './steps/5_WorkingWithYou/WorkingWithYou'

const RECOMMENDED_PARTNER_STEPS: Record<
  RecommendedPartnerStep,
  React.ReactNode
> = {
  watchVideo: <WatchVideo />,
  whereWeCanHelp: <WhereWeCanHelp />,
  whatOurClientsSay: <WhatOurClientsSay />,
  whatProfessionalsSay: <WhatProfessionalsSay />,
  workingWithYou: <WorkingWithYou />,
}

const RecommendedPartnerModal: React.FC = () => {
  const modal = useRecommendedPartnerModal()
  const recommendedPartnerQuery = useRecommendedPartnerQuery()
  const recommendedPartner = recommendedPartnerQuery.data
  const { initialStep, currentStep } = useRecommendedPartnerSteps()
  const companyName = recommendedPartner.company.presentationName
  const isMinWidthMd = useIsMinWidthMd()
  const logoUrl = recommendedPartner.company.logoUrl
  const scheduleCallUrl = recommendedPartner.company.scheduleCompanyCallUrl

  useMount(() => {
    modal.setStep(initialStep)
  })

  return (
    <>
      <Helmet title={`Vetted Partner | ${companyName}`} />

      <Modal.Header
        onHide={modal.hide}
        className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between gap-2 gap-sm-4 position-relative"
      >
        <div className="d-none d-md-block" />

        <Modal.Title
          className="text-xxl font-weight-bold"
          style={{ lineHeight: 1.4 }}
        >
          Introducing {companyName}
        </Modal.Title>

        <Image
          src={logoUrl || undefined}
          fallback={<div />}
          alt={companyName}
          className="img-fluid fs-0 me-3"
          style={{ maxWidth: '120px', maxHeight: '60px' }}
        />

        <FontAwesomeIcon
          icon={faTimes}
          onClick={modal.hide}
          className="d-sm-none position-absolute top-0 end-0"
          style={{ fontSize: '22px', padding: '10px 14px' }}
          role="button"
          aria-label="Close modal"
        />
      </Modal.Header>

      <RecommendedPartnerTabLinks />

      <Modal.Body
        className="font-weight-300"
        style={{
          lineHeight: 1.9,
          padding: isMinWidthMd ? '28px 35px 10px' : '28px 13px 10px',
        }}
      >
        {RECOMMENDED_PARTNER_STEPS[currentStep]}
      </Modal.Body>

      <Modal.Footer className="pe-4 pb-3 pb-md-1">
        {scheduleCallUrl && (
          <a
            href={scheduleCallUrl}
            target="_blank"
            rel="noreferrer"
            className="btn btn-outline-secondary btn-lg btn-fluid"
          >
            Schedule a call
          </a>
        )}

        <AddToReferralPartnersButton variant="primary" />
      </Modal.Footer>
    </>
  )
}

export default RecommendedPartnerModal
