import classNames from 'classnames'
import React from 'react'

type Props = React.HTMLAttributes<HTMLHeadingElement>

const SectionHeading: React.FC<Props> = ({ className, style, ...props }) => (
  <h2
    className={classNames('text-black font-weight-600 mb-2', className)}
    style={{ fontSize: '17px', ...style }}
    {...props}
  />
)

export default SectionHeading
