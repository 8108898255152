import React, { CSSProperties } from 'react'
import Skeleton from 'react-loading-skeleton'

interface Props {
  className?: string
  style?: CSSProperties
  height?: number
}

const SkeletonRow: React.FC<Props> = ({ className, style, height = 25 }) => {
  return (
    <Skeleton
      className={className}
      containerClassName="w-100"
      style={{ height: `${height}px`, ...style }}
    />
  )
}

export default SkeletonRow
