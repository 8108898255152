import React from 'react'

import { useRecommendedPartner } from '../../../../hooks/useRecommendedPartner'
import HtmlContent from '../../components/HtmlContent'
import SectionContainer from '../../components/SectionContainer'
import SectionHeading from '../../components/SectionHeading'

const WhatOurClientsSay: React.FC = () => {
  const partner = useRecommendedPartner()

  return (
    <SectionContainer>
      <SectionHeading>What our clients say about us</SectionHeading>

      <HtmlContent
        html={partner.clientTestimonials || ''}
        style={{ maxWidth: '600px' }}
      />
    </SectionContainer>
  )
}

export default WhatOurClientsSay
